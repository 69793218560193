import { useEffect } from "react";
import { Box } from "@mui/material";
import * as React from "react";
import Favicon from "react-favicon";

export default function MetaHeader({ children }) {
  useEffect(() => {
    document.title = "Chuk Market POS";
  }, []);

  return (
    <Box>
      <Favicon url={"/assets/icons/favicon.ico"} />
      {children}
    </Box>
  );
}
